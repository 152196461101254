import axios from 'axios';
import store from '../store';

export default {
    getWeeks: () => axios.get('admin/contract/weeks'),
    getVisits: (contract_id, week) => axios.post('admin/contract/visits', {contract_id: contract_id, week: week}),
    getAvailableVisits: (contract_id, week) => axios.post('admin/contract/available-visits', {contract_id: contract_id, week: week}),
    cloneVisits: (contract_id, source_week, target_week) => axios.post('admin/contract/clone-visits', {contract_id: contract_id, source_week: source_week, target_week: target_week}),
    changeVisit: (visit_id, day) => axios.post('admin/contract/change-visit', {visit_id: visit_id, day: day}),
    addVisit: (contract_id, store_id, branch_id, week, day) => axios.post('admin/contract/add-visit', {contract_id: contract_id, store_id: store_id, branch_id: branch_id, week: week, day: day}),
    getContracts: () => axios.get('admin/contracts'),

    getCollaboratorVisits: (collaborator_id, week) => axios.post('admin/collaborator/visits', {collaborator_id: collaborator_id, week: week}),
    getCollaboratorAvailableVisits: (collaborator_id, week) => axios.post('admin/collaborator/available-visits', {collaborator_id: collaborator_id, week: week}),

    getStores: (contract_id) => axios.get('admin/contract/'+contract_id+'/stores'),
    getBranchs: (contract_id) => axios.get('admin/contract/'+contract_id+'/branchs'),
    getStoreBranchs: (contract_id, store_id) => axios.get('admin/contract/'+contract_id+'/'+store_id+'/store-branchs'),
    addBranch: (contract_id, store_id, branch_id) => axios.post('admin/contract/add-branch', {contract_id: contract_id, store_id: store_id, branch_id: branch_id}),
    getProducts: (contract_id) => axios.get('admin/contract/'+contract_id+'/products'),
    getCollaborators: (contract_id) => axios.get('admin/contract/'+contract_id+'/collaborators'),
    changeCollaborator: (visit_id, collaborator_id) => axios.post('admin/contract/change-collaborator', {visit_id: visit_id, collaborator_id: collaborator_id}),
}